$color-primary: #e1620e;
/**AUTO SUGGEST**/

.react-autosuggest__container {
    position: relative;
    width: 100%;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-appearance: none;
    width: 100%;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    // width: 280px;
    // border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    float: left;
    width: 100%;
}

.react-autosuggest__suggestion--highlighted {
    background-color: rgb(245, 245, 245);
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
}

/**AUTO SUGGEST **/
.navbar-brand,
.footer_logo img {
    max-width: 140px;
}
.SingleProductInSlider {
    margin-right: 15px;
    .product_img {
        text-align: center;
        img {
            display: inline-block;
        }
    }
}
.ReactModal__Overlay {
    background-color: rgba(57, 57, 57, 0.75) !important;
    z-index: 999999 !important;
}
.ReactModal__Content {
    background-color: transparent !important;
    border: none !important;
}
.SlickSliderWrapper {
    &.SlickStyleDefault {
        .slick-dots {
            position: absolute;
            bottom: 15px;
            right: 0;
            left: 0;
            padding: 3px 0;
            margin: 0;
            li {
                vertical-align: middle;
                background-color: rgba(0, 0, 0, 0.6);
                width: 10px;
                height: 10px;
                border-radius: 5px;
                transition: all ease 0.2s;
                button {
                    width: 10px;
                    height: 10px;
                    text-indent: -50px;
                    overflow: hidden;
                    opacity: 0;
                }
                &.slick-active {
                    background-color: rgba(0, 0, 0, 1);
                }
            }
        }
    }
    &.StickySylePrimaryStatic {
        .slick-dots {
            position: static;
            li {
                background-color: transparent;
                border: solid 1px $color-primary;
                &.slick-active {
                    background-color: $color-primary;
                    width: 30px;
                }
            }
        }
    }
}
.SingleProductInSlider {
    .product_wrap {
        margin-bottom: 15px;
        margin-top: 10px;
    }
}
.product-d-block {
    width: 100%;
}
.ReactModalCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}
.mfp-ajax-holder .mfp-content .ajax_quick_view {
    padding: 15px;
}
.mfp-container {
    position: relative;
    .mfp-container--ajax-refresh {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 55;
    }
}

/**PRODUCT IMAGE**/
.ProductImageWrapper {
    .ProductImageLoading {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;
        margin: auto;
        &[aria-hidden="false"] {
            display: none;
        }
    }
    .PhotosCount {
        padding: 8px 0 10px 0;
        font-size: 13px;
    }
    figure {
        position: relative;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 200%;
        margin: 0;
        &:hover {
            img:not(.no-image) {
                user-select: none;
                opacity: 0;
            }
        }
        img {
            display: block;
            width: 100%;
            pointer-events: none;
        }
    }
    .ProductImageMain {
    }
    .ProductImageThumbs {
        padding: 0 5px;
        ul {
            padding: 0;
            margin: 0;
            margin-right: -15px;
            li {
                padding-right: 15px;
                cursor: pointer;
                &:hover,
                &:focus,
                &:active {
                    opacity: 0.8;
                }
                &[aria-selected="true"] {
                    img {
                        border: solid 2px #000;
                    }
                }
                img {
                    width: 100%;
                }
            }
        }
    }
}

.ReactModal__Body--open {
    overflow-y: hidden;
}
.ReactModal__Content.ReactModal__Content--after-open {
    top: 0 !important ;
    right: 0 !important ;
    left: 0 !important ;
    bottom: 0 !important;
}
.ellipsis-block {
    overflow: hidden;
    position: relative;
}
.ellipsis-block {
    .ellipsis-wrapper {
        .read-less-btn {
            display: none;
        }
        .read-more-button,
        .read-less-btn {
            font-weight: bold;
            color: #000;
            cursor: pointer;
            &:hover,
            &:focus,
            &:active {
                color: rgb(59, 59, 59);
            }
        }
        .ellipsis-c-h {
            display: none;
        }
        &[aria-expanded="true"] {
            .ellipsis-c-h {
                display: inline;
            }
            p.ellipsis-c-h {
                display: block;
            }
            .read-more-button {
                display: none;
            }
            .read-less-btn {
                display: inline-block;
            }
        }
    }
}
.switch_lable {
    text-transform: capitalize;
}
.pr_switch_wrap {
    display: flex;
    flex-direction: row;
    border-bottom: dashed 1px #eaeaea;
    padding: 3px 0;
    .product_size_switch span {
        width: initial;
        margin: 0 3px;
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        user-select: none;
        &.disabled {
            cursor: default;
            opacity: 0.2;
        }
        &:hover,
        &:focus,
        &:active {
            &:not(.active) {
                background-color: rgb(14, 14, 14);
                border-color: rgb(14, 14, 14);
            }
        }
        &.active {
            border-color: #e1620e;
        }
    }
    .switch_lable {
        min-width: 80px;
    }
}
.listing-style-switch {
    cursor: pointer;
    &.active {
        color: #fff !important;
    }
}
.product_img {
    // display: flex;
    // justify-content: center;
    // align-items: center;
}
.ShopCategory {
    &.active {
        color: #e1620e;
    }
}
.widget_categories li {
    display: flex;
    flex-direction: row;
    a,
    span {
        display: flex;
        flex: auto;
    }
    .categories_num {
        align-items: flex-start;
        justify-content: flex-end;
    }
}
.ProductOverlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.HeaderSecondary {
    .bottom_header {
        box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
    }
}
.PhoneInput {
    height: 50px !important ;
    width: 100% !important;
}
.UserAuthOverlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    user-select: none;
}
.ErrorMessage {
    color: #ff5454;
    font-size: 15px;
}
.HasError {
    border-color: rgb(218, 0, 0);
}
.KeepFocus {
    border-color: rgb(218, 0, 0) !important;
}
.ErrorValidation {
    color: #ff5a44;
    padding: 4px 0;
    text-align: initial;
}

.focus-visible + .ErrorValidation {
    display: none !important ;
}
.KeepFocus + .ErrorValidation {
    display: block !important ;
}
.section-404 {
    width: 100%;
}
.search_btn3 {
    line-height: 30px;
}
.contents-wrapper {
    min-height: 100vh;
}
.CartUpdateOverlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.RemoveCartItem {
    cursor: pointer;
}
.product_img img {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.shop_container_custom {
    position: relative;
}
.ProductDetailPage {
    padding: 50px 0 !important;
    .mfp-ajax-holder .mfp-content .ajax_quick_view {
        max-width: 100% !important;
        padding-left: 0 !important ;
        padding-right: 0 !important ;
        margin-bottom: 60px;
    }
}
.AuthError {
    color: #ff5454;
    margin-top: 15px;
    text-align: center;
}
.SocialShareButton {
    background-color: transparent !important;
    margin: 0 !important ;
    * {
        fill: rgba(57, 57, 57, 0.75) !important;
    }
    span {
        display: none;
    }
}
.BannerBoxSide {
    background-size: cover;
    .el_title {
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    }
    &.shop_banner2 a {
        background: rgba(0, 0, 0, 0.4) !important;
    }
}
.CategorySliderTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    margin: 0;
    padding: 13px;
    font-size: 22px;
    font-weight: 200;
}
.CategoryLink {
    width: 100%;
    display: block;
    img {
        width: 100%;
        height: auto;
        opacity: 0;
    }
}
.CategoryBannerItem {
    background-size: cover;
    background-color: #000;
}
.SingleProductInSlider .product_img img {
    width: 100%;
}
.modal-content-small {
    max-width: 550px;
    background-color: #fff;
    margin: auto;
    width: 80%;
}
.modal-content-medium {
    max-width: 850px;
    background-color: #fff;
    margin: auto;
    width: 80%;
}
.ReactModal__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pr_action_btn {
    li {
        a {
            cursor: pointer;
            &:hover,
            &:focus,
            &:active {
                i {
                    color: #fff;
                }
            }
        }
    }
}
.AjaxOverlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
}
.dropdown-header {
    white-space: pre-line;
}
.ProductAjaxCart {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
}
.item,
.SingleProductInSlider {
    position: relative;
}
.OrderOverlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 50;
}
.CheckoutError {
    color: #f44336;
    padding: 16px 0;
    text-align: center;
}
.widget {
    i,
    a {
        color: #687188;
    }
}
.popup_content_left_aligned {
    .form-group,
    .chek-form {
        text-align: initial;
    }
}
.ProductNamePopup {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px 20px;
    color: rgb(14, 14, 14);
    font-size: 20px;
}
.RemoveFromWishList {
    cursor: pointer;
}
a {
    cursor: pointer;
}
.LinkButton {
    cursor: pointer;
    color: #fff !important;
}
.contact_phone i {
    font-size: 18px;
}

.react-autosuggest__suggestions-container {
    display: block !important ;
}

.search_item_wrapper {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    padding: 0px 0;
    .search_item_thumb {
        width: 60px;
        display: inline-block;
        float: left;
    }
    .search_item_details.has-image {
        padding-left: 70px;
    }
    .search_item_cat {
        font-size: 12px;
        color: $color-primary;
    }
    .search_item_name {
        font-size: 13px;
        font-weight: 500;
        color: #000;
    }
}
.search_action {
    display: block;
    float: left;
    width: 100%;
}
.top-search-box {
    width: 380px;
}
.SearchForm {
    input {
    }
    &.dark-mode {
        input {
            background-color: #e6e6e6;
            border: none;
            border-radius: 0;
        }
    }
}
@media screen and (max-width: 767px) {
    .dropdown {
        a {
            position: relative;
        }
    }
    .dropdown-menu.show {
        left: 10px !important;
    }
    .dropdown-item.nav-link.dropdown-toggler {
        font-weight: bold;
    }
    .more_categories {
        display: none !important ;
    }
    .more_slide_open {
        display: block !important ;
    }
    .dropdown-mega-menu{
        .dropdown-menu{
            display:block !important ;
        }
    }
}
